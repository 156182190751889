import { clinics, members } from "../../js/path";

export default {
  data() {
    return {
      socialRes: false,
      socialLinks: [],
      social_link_fields: [
        {
          key: "id",
        },
        {
          key: "question",
        },
        {
          key: "answer",
        },
        {
          key: "delete",
        },
      ],
      pageNo: null,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      currentPage: 1,
      params: "",
      key: 0,
      social_link: {},
      form: {
        social_links: {},
      },
    };
  },
  methods: {
    async getSocialLinks() {
      try {
        this.$store.commit("loader/updateStatus", true);
        const url = members.getSocialLinks;
        const res = await this.getRequest(url);
        if (res.status) {
          this.socialLinks = res.response;
          this.socialLinks.forEach((link) => {
            this.$set(this.form.social_links, link.value, "");
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async deleteSocialLink(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        const index = this.social_link.findIndex((e) => e.id == id);
        const url = `${clinics?.social_link}/${id}`;
        const res = await this.postRequest(url, {
          _method: "delete",
        });
        if (res.status) {
          this.social_link.splice(index, 1);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchSocialLinkList() {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getSocialLinks}/${this.$route.params.id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.social_link = data.response;
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchSingleHospital(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = `${clinics?.getSocialLinks}/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          const responseData = data.response;
          if (!responseData) {
            this.socialRes = false;
          } else {
            this.socialRes = true;
          }
          const links = JSON.parse(responseData.links || "{}");
          this.socialLinks.forEach((link) => {
            if (Object.prototype.hasOwnProperty.call(links, link.value)) {
              this.$set(this.form.social_links, link.value, links[link.value]);
            } else {
              this.$set(this.form.social_links, link.value, "");
            }
          });
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async submitData() {
      const allFieldsFilled = Object.values(this.form.social_links).every(
        (value) => value.trim() !== ""
      );
      if (!allFieldsFilled) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Fill out all social link fields.",
        });
        return;
      }
      this.$store.commit("loader/updateStatus", true);
      try {
        let dataAppend = new FormData();
        if (this.form.social_links) {
          for (const [key, value] of Object.entries(this.form.social_links)) {
            dataAppend.append(`social_links[${key}]`, value || "");
          }
        }
        dataAppend.append("clinic_id", this.$route.params.id);
        let url = null;
        if (!this.socialRes) {
          url = `${clinics?.getSocialLinks}`;
        } else {
          url = `${clinics?.getSocialLinks}/${this.$route.params.id}`;
          dataAppend.append("_method", "put");
        }
        const data = await this.postRequest(url, dataAppend);
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
          this.$router.push(`/clinic/edit-clinic/${this.$route.params.id}`);
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
};
