<template>
  <Layout>
    <PageHeader title="Add Social Link" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <div class="socialLinks">
                <div v-for="(item, index) in socialLinks" :key="index">
                  <b-form-group class="mr-4">
                    <label
                      >{{ item.name }} <span style="color: red">*</span></label
                    >
                    <b-form-input
                      :id="'input-' + index"
                      :placeholder="'Enter ' + item.value + ' link'"
                      v-model="form.social_links[item.value]"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2 mt-2"
                @click.prevent="submitData"
                >Save Data
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import clinicMixin from "@/mixins/ModuleJs/social-links";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, clinicMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  async created() {
    this.getSocialLinks();
    await this.fetchSingleHospital(this.$route.params.id);
  },
};
</script>
